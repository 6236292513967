import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import {
  CoachStatusEnum,
  useInsertVideoAnalysisSubmissionLogMutation,
  useUpdateVideoAnalysisSubmissionLogMutation,
} from 'types/generated/client';
import { FileCategoryEnum, FileFormatEnum } from 'types/generated/server';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { getLessonItemizedTotal } from 'utils/shared/money/getLessonItemizedTotal';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useModal } from 'hooks/useModal';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useUploadVideoFile } from 'hooks/useUploadVideoFile';
import { useViewer } from 'hooks/useViewer';
import Alert from 'svg/Alert';
import CloseIcon from 'svg/CloseIcon';
import Button from 'components/Button';
import FileUploader, { SelectedFile } from 'components/VideoAnalysis/FileUploader';
import Skills from 'components/VideoAnalysis/Skills';
import { isVideoDurationValid } from 'components/VideoAnalysis/utils';
import InputField from 'components/coaches-builder/InputField';
import { InputType } from 'components/coaches-builder/InputField/InputField';
import Modal from 'components/modals/Modal';
import BankAccountPrompt from '../BankAccountPrompt';
import { Steps, VideoAnalysisSubmissionProps } from '../types';

type VideoUpload = {
  fileCategory: string;
  fileFormat: string;
  fileName: string;
  fileType: string;
  host: string;
  originalFileName: string;
  provider: string;
  providerId: string;
  providerUrl: string;
  url: string;
  userId: string;
};

const VideoAnalysisSubmission = ({
  coach,
  setSteps,
  setSubmissionData,
  handleClose,
}: VideoAnalysisSubmissionProps) => {
  const { user } = useGetCurrentUser();
  const [insertVideoAnalysisSubmissionLogMutation] = useInsertVideoAnalysisSubmissionLogMutation();
  const [updateVideoAnalysisSubmissionLogMutation] = useUpdateVideoAnalysisSubmissionLogMutation();
  const {
    isLoading: isLoading,
    setLoading: setVideoLoading,
    setSuccess: setVideoSuccess,
    setError: setVideoError,
  } = useRequestStatus();
  const { uploadVideoFile, isLoadingUpload, errorUpload, uploadProgress } = useUploadVideoFile();
  const {
    isOpen: isUploadErrorModalOpen,
    openModal: openUploadErrorModal,
    closeModal: closeUploadErrorModal,
  } = useModal();

  useEffect(() => {
    if (errorUpload) {
      openUploadErrorModal();
    }
  }, [errorUpload]);

  const viewer = useViewer();
  const userId = user?.id;
  const coachId = coach?.id;
  const isCoach = user?.coachStatus === CoachStatusEnum.Active;

  const coachPrice = coach?.priceUnitAmountRemoteCoachDefault ?? 0;
  const params = {
    priceUnitAmount: coachPrice,
  };
  const lessonTotal = getLessonItemizedTotal(params);

  const [getVideoUploadId, setGetVideoUploadId] = useState<string>('');

  //Schemas
  const videoAnalysisFilesSchema = z.object({
    fileCategory: z.enum([
      FileCategoryEnum.Coach,
      FileCategoryEnum.Other,
      FileCategoryEnum.Profile,
    ]),
    fileName: z.string(),
    fileType: z.string(),
    host: z.string(),
    originalFileName: z.string(),
    provider: z.string(),
    providerUrl: z.string(),
    url: z.string(),
    fileFormat: z.enum([FileFormatEnum.Image, FileFormatEnum.Video]),
    providerId: z.string(),
    userId: z.string(),
  });

  const videoUploadingSchema = z.object({
    videoAnalysisFiles: z.array(videoAnalysisFilesSchema).refine((files) => files.length > 0, {
      message: 'Video is required',
    }),
    details: z.string().optional(),
    skills: z.array(z.string()).optional(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm<z.infer<typeof videoUploadingSchema>>({
    resolver: zodResolver(videoUploadingSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      videoAnalysisFiles: [],
      details: '',
      skills: [],
    },
  });

  type VideoUploadingForm = z.infer<typeof videoUploadingSchema>;

  const onSubmit = async (data: any) => {
    setSubmissionData && setSubmissionData(data);
    try {
      if (viewer.isAnonymousSession) {
        setSteps(Steps.CreateAccount);
      } else {
        setSteps(Steps.PaymentForm);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  const handleBeforeFileSelection = () => {
    const isLoggedIn = !!userId;
    if (!isLoggedIn) {
      setSteps(Steps?.CreateAccount);
    }

    return isLoggedIn;
  };

  const startUploadingFile = async (file: File) => {
    const isVideo = file.type.startsWith('video/');

    if (isVideo) {
      let insertVideoAnalysisSubmission = await insertVideoAnalysisSubmissionLogMutation({
        variables: {
          userId: userId || null,
          videoName: file?.name,
        },
      });

      setGetVideoUploadId(
        insertVideoAnalysisSubmission?.data?.insertVideoAnalysisSubmissionLogOne?.id,
      );

      const isValidDuration = await isVideoDurationValid(file, 900); // Custom max seconds (900s = 15 minutes)

      if (!isValidDuration) {
        setError('videoAnalysisFiles', {
          type: 'manual',
          message: 'Video exceeds 15 minutes. Please upload a shorter video.',
        });
        throw new Error('Video exceeds duration limit');
      }
      try {
        const uploadResponse = await uploadVideoFile({ file });

        await updateVideoAnalysisSubmissionLogMutation({
          variables: {
            userId: userId,
            videoName: uploadResponse?.originalFileName || uploadResponse?.fileName,
            videoPath: uploadResponse?.path,
            id:
              getVideoUploadId ||
              insertVideoAnalysisSubmission?.data?.insertVideoAnalysisSubmissionLogOne?.id,
          },
        });

        return {
          fileCategory: FileCategoryEnum.Other || '',
          fileName: uploadResponse?.fileName || '',
          fileType: uploadResponse?.fileType || 'image/jpeg',
          host: uploadResponse?.host || 'defaultHost',
          originalFileName: uploadResponse?.originalFileName || uploadResponse?.fileName || '',
          provider: uploadResponse?.provider || '',
          providerUrl: uploadResponse?.providerUrl || '',
          url: uploadResponse?.url || '',
          fileFormat: FileFormatEnum.Video || '',
          providerId: '',
          userId: userId || '',
        };
      } catch (error) {
        setVideoError(error as string);
        throw error;
      }
    }
  };

  const handleSelectFiles = async (files: File[], onChange: (...event: any[]) => void) => {
    try {
      clearErrors('videoAnalysisFiles');
      setVideoLoading();
      const uploadPromises = files.map(async (file) => {
        return startUploadingFile(file);
      });
      const payloads = (await Promise.all(uploadPromises)) as VideoUpload[];
      if (!payloads || payloads.length === 0) return;

      onChange([...payloads]);
      setVideoSuccess();
    } catch (error) {
      console.error('Error uploading files:', error);
      setVideoError('');
    }
  };

  const handleRemoveFile = (
    fileToRemove: SelectedFile,
    currentFiles: VideoUpload[],
    onChange: (...event: any[]) => void,
  ) => {
    const filteredFiles = currentFiles.filter(
      (currentFile) => currentFile.url !== fileToRemove.url,
    );
    onChange(filteredFiles);
  };

  return (
    <>
      {userId !== coachId && (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="overflow-y-auto max-sm:max-h-[calc(100dvh-3rem)] sm:max-h-[calc(100dvh-10rem)]"
          >
            <div className="p-6">
              <div className="flex w-full flex-row items-center justify-between">
                <div className="flex gap-1">
                  {handleClose && (
                    <button type="button" className="shrink-0 p-0" onClick={() => handleClose()}>
                      <CloseIcon className="h-5 w-5 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary" />
                    </button>
                  )}
                  <h2 className="typography-product-heading flex-1 text-color-text-brand dark:text-color-text-brand">
                    Video analysis
                  </h2>
                </div>
                <span className="typography-product-heading text-color-text-brand">
                  {
                    convertUnitPriceToFormattedPrice(
                      lessonTotal?.orderSubtotalWithPlatformFeesUnitAmount ?? 0,
                    ).priceDisplay
                  }
                </span>
              </div>

              <p className="typography-product-body mt-8 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                Submit a video of you playing and I’ll give you point-by-point feedback.
              </p>
              {/* Video Upload */}
              <div className="mt-5 h-full w-full flex-grow flex-col gap-3">
                <h6 className="typography-product-subheading mb-4 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                  Upload your video
                </h6>

                <Controller
                  control={control}
                  name="videoAnalysisFiles"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FileUploader
                        uploadProgress={uploadProgress}
                        multiple={false}
                        accept={{ 'video/*': ['.mp4', '.mov'] }}
                        styles="rounded-md"
                        selectedFiles={value || []}
                        onRemoveFile={(fileToRemove) =>
                          handleRemoveFile(fileToRemove, value, onChange)
                        }
                        beforeFileSelection={handleBeforeFileSelection}
                        onSelectFiles={(files) => handleSelectFiles(files, onChange)}
                        helperText="MP4, MOV (max. 10 Mins)"
                      />
                    );
                  }}
                />

                {errorUpload && <p className="mt-2 text-xs text-color-error">{errorUpload}</p>}
                {errors.videoAnalysisFiles && (
                  <p className="mt-2 text-xs text-color-error">
                    {errors?.videoAnalysisFiles?.message}
                  </p>
                )}
              </div>
              {/* Skills Section */}
              <Skills<VideoUploadingForm>
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
              <div className="flex flex-col gap-7">
                <div className="mt-4 flex w-full flex-col gap-3">
                  <h6 className="typography-product-subheading text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
                    Provide the coach with details
                  </h6>
                  <InputField
                    fieldLabel="coachDescription"
                    errors={errors}
                    placeholder="Add description (optional)"
                    name="details"
                    register={register}
                    inputType={InputType.Textarea}
                  />
                </div>
              </div>

              <div className="sticky bottom-5 z-10 mt-5">
                <Button
                  disabled={isLoading}
                  type="submit"
                  size="lg"
                  className="w-full"
                  variant="brand"
                  label="Next"
                />
              </div>
            </div>
          </form>
        </>
      )}
      {isCoach && userId === coachId && (
        <div className="md:mt-5">
          <BankAccountPrompt
            heading={'This is your page!'}
            description={`Unfortunately, you can't book a lesson with yourself. Share your page so others can take advantage of your lessons today!`}
            showButton={false}
            showBack={false}
            showBackAction={() => {}}
            inCard
          />
        </div>
      )}
      <Modal isOpen={isUploadErrorModalOpen} handleClose={() => closeUploadErrorModal()}>
        <div className="flex flex-col items-center gap-4 p-6 text-center">
          <Alert className="h-10 w-10" />
          <h2 className="typography-product-heading text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            Upload failed
          </h2>
          <p className="typography-product-body text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            Please ensure you are on stable Wi-Fi and try uploading the video again.
          </p>
          <Button
            variant="brand"
            size="lg"
            className="w-full"
            onClick={() => closeUploadErrorModal()}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VideoAnalysisSubmission;
